<template>
  <!--begin::Toolbar-->
  <div class="toolbar d-none" id="kt_toolbar">
    <!--begin::Container-->
    <div id="kt_toolbar_container" class="container-fluid d-flex flex-stack">
      <!--begin::Page title-->
      <div
        class="page-title d-flex align-items-center me-3 flex-wrap mb-5 mb-lg-0 lh-1"
      >
        <!--begin::Title-->
        <!--<h1
          class="d-flex align-items-center text-dark fw-bolder my-1 fs-3"
        >
          {{ title }}
        </h1>-->
        <!--end::Title-->

        <!--<span
          v-if="breadcrumbs"
          class="h-20px border-gray-200 border-start mx-4"
        ></span>-->

        <!--begin::Breadcrumb-->
      </div>
      <!--end::Page title-->

      <!--begin::Actions-->
      <div class="d-flex align-items-center py-1">
        <!--begin::Wrapper-->
        <div class="me-4">
          <!--begin::Filter-->
          <div :class="{ 'd-none': type !== 'assetsList', 'd-block': type === 'assetsList' }">
            <AssetsFilterDropdown></AssetsFilterDropdown>
          </div>
          <!--end::Filter-->
        </div>
        <!--end::Wrapper-->

        <!--begin::Button-->
        <a
          href="#"
          class="btn btn-sm btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#kt_modal_create_app"
          id="kt_toolbar_primary_button"
          v-if="type === 'xxxxa'"
        >
          Create
        </a>

        <!--<a
            href="#"
            class="btn btn-sm btn-primary"
            v-if="['assetsList', 'ddb-checklists'].includes(type)"
        >
          Create
        </a>-->

        <a
            v-if="type && type === 'ddb-checklist-view'"
            href="#"
            class="btn btn-sm btn-primary"
            id="reviseDdbChecklist"
        >
          Revise
        </a>

        <!--end::Button-->
      </div>
      <!--end::Actions-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Toolbar-->
</template>

<script lang="ts">
import { defineComponent, onBeforeMount } from "vue";
import ProjectsFilterDropdown from "@/components/dropdown/ProjectsFilterDropdown.vue";
import AssetsFilterDropdown from "@/components/dropdown/AssetsFilterDropdown.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import { util } from "prismjs";
import type = util.type;

//let typesWithFilter = Array<string>();
export default defineComponent({
  name: "KToolbar",
  props: {
    breadcrumbs: Array,
    title: String,
    type: String
  },
  components: {
    AssetsFilterDropdown
  }
});
</script>
