
import { inject, defineComponent, ref, computed} from "vue";
import { Field } from "vee-validate";
import { useStore } from "vuex";
import store from "@/store";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {emitter} from "@/main";

interface Filter {
  status: string;
  calibration: string;
  type: string;
  manufacturer: string;
  keyword: string;
  claims: string;
}

export default defineComponent({
  name: "assets-filter-dropdown",
  components: {Field},
  methods: {
    resetFilters: function() {
      console.log("reset");
      this.data.status = "Hamısı";
      this.data.calibration = "Hamısı";
      this.data.type = "Hamısı";
      this.data.manufacturer = "Hamısı";
      this.data.keyword = "";
      this.data.claims = "Hamısı";
      this.filterAssetsList();
    },
    updateAssetsFilterStatus: function (selected) {
      this.data.status = selected;
      this.filterAssetsList();
    },
    updateAssetsFilterCalibration: function (selected) {
      this.data.calibration = selected;
      this.filterAssetsList();
    },
    updateAssetsFilterManufacturer: function (selected) {
      this.data.manufacturer = selected;
      this.filterAssetsList();
    },
    updateAssetsFilterType: function (selected) {
      this.data.type = selected;
      this.filterAssetsList();
    },
    updateAssetsFilterKeyword: function (selected) {
      this.data.keyword = selected.target.value;
      this.filterAssetsList();
    },
    updateAssetsFilterClaims: function (selected) {
      this.data.claims = selected;
      this.filterAssetsList();
    },
    filterAssetsList: function() {
      //console.log("update triggered", this.data);
      emitter.emit('filterAssetsList', this.data);
    }
  },
  setup() {
    const data = ref<Filter>({
      status: "Hamısı",
      calibration: "Hamısı",
      type: "Hamısı",
      manufacturer: "Hamısı",
      claims: "Hamısı",
      keyword: ""
    });

    return {
      data
    };
  }
});
