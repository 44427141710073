
import { defineComponent } from "vue";
import AssetsFilterDropdown from "@/components/dropdown/AssetsFilterDropdown.vue";

export default defineComponent({
  name: "mobile-page-title",
  components: {AssetsFilterDropdown},
  props: {
    breadcrumbs: Array,
    title: String
  }
});
