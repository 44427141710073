
import { defineComponent, onBeforeMount } from "vue";
import ProjectsFilterDropdown from "@/components/dropdown/ProjectsFilterDropdown.vue";
import AssetsFilterDropdown from "@/components/dropdown/AssetsFilterDropdown.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import { util } from "prismjs";
import type = util.type;

//let typesWithFilter = Array<string>();
export default defineComponent({
  name: "KToolbar",
  props: {
    breadcrumbs: Array,
    title: String,
    type: String
  },
  components: {
    AssetsFilterDropdown
  }
});
