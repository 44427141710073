<template>
  <!--begin::Page title-->
  <div
    data-kt-swapper="true"
    class="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0"
  >
    <!--begin::Title-->
    <h1 class="d-flex align-items-center text-dark fw-bolder my-1 fs-3">
      {{ title }}
    </h1>
    <!--end::Title-->

    <div class="me-4">
      <!--begin::Filter-->
      <div :class="{ 'd-none': type !== 'assetsList', 'd-block': type === 'assetsList' }">
        <AssetsFilterDropdown></AssetsFilterDropdown>
      </div>
      <!--end::Filter-->
    </div>
    <!--end::Wrapper-->
  </div>
  <!--end::Page title-->
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AssetsFilterDropdown from "@/components/dropdown/AssetsFilterDropdown.vue";

export default defineComponent({
  name: "mobile-page-title",
  components: {AssetsFilterDropdown},
  props: {
    breadcrumbs: Array,
    title: String
  }
});
</script>
