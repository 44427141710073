<template>
  <!--begin::Assets filter dropdown-->
  <a
      href="#"
      class="btn btn-sm btn-flex btn-light btn-active-primary fw-bolder d-flex d-sm-none"
      data-kt-menu-trigger="click"
      data-kt-menu-placement="bottom-end"
      data-kt-menu-flip="top-end"
  >
            <span class="svg-icon svg-icon-5 svg-icon-gray-500 me-1">
              <inline-svg src="media/icons/duotone/Text/Filter.svg" />
            </span>
    Filtr
  </a>
  <div
    class="menu menu-sub menu-sub-dropdown w-250px w-md-300px"
    data-kt-menu="true"
  >
    <!--begin::Header-->
    <div class="px-7 py-5">
      <div class="fs-5 text-dark fw-bolder">Filtr parametrləri</div>
    </div>
    <!--end::Header-->

    <!--begin::Menu separator-->
    <div class="separator border-gray-200"></div>
    <!--end::Menu separator-->

    <!--begin::Form-->
    <div class="px-7 py-5">
      <!--begin::Input group-->
      <div class="mb-5">
        <!--begin::Label-->
        <label class="form-label fw-bold">İstehsalçı:</label>
        <!--end::Label-->

        <!--begin::Input-->
        <div>
          <el-select
              class="form-select-solid"
              placeholder="Select option"
              v-model="data.manufacturer"
              @change="updateAssetsFilterManufacturer"
          >
            <el-option label="Hamısı" value="Hamısı">Hamısı</el-option>
            <el-option label="Elster" value="Elster">Elster</el-option>
            <el-option label="GCZ" value="GCZ">GCZ</el-option>
          </el-select>
        </div>
        <!--end::Input-->
      </div>
      <!--end::Input group-->

      <!--begin::Input group-->
      <div class="mb-5">
        <!--begin::Label-->
        <label class="form-label fw-bold">Növü:</label>
        <!--end::Label-->

        <!--begin::Input-->
        <div>
          <el-select
              class="form-select-solid"
              placeholder="Select option"
              v-model="data.type"
              @change="updateAssetsFilterType"
          >
            <el-option label="Hamısı" value="Hamısı">Hamısı</el-option>
            <el-option label="BK-G4T" value="BK-G4T">BK-G4T</el-option>
            <el-option label="BK-G2.5T" value="BK-G2.5T">BK-G2.5T</el-option>
          </el-select>
        </div>
        <!--end::Input-->
      </div>
      <!--end::Input group-->

      <!--begin::Input group-->
      <div class="mb-5">
        <!--begin::Label-->
        <label class="form-label fw-bold">Status:</label>
        <!--end::Label-->

        <!--begin::Input-->
        <div>
          <el-select
            class="form-select-solid"
            placeholder="Select option"
            v-model="data.status"
            @change="updateAssetsFilterStatus"
          >
            <el-option label="Hamısı" value="Hamısı">Hamısı</el-option>
            <el-option label="İşlənmiş" value="used">İşlənmiş</el-option>
            <el-option label="Yeni" value="new">Yeni</el-option>
          </el-select>
        </div>
        <!--end::Input-->
      </div>
      <!--end::Input group-->

      <!--begin::Input group-->
      <div class="mb-5">
        <!--begin::Label-->
        <label class="form-label fw-bold">Şikayət:</label>
        <!--end::Label-->

        <!--begin::Input-->
        <div>
          <el-select
              class="form-select-solid"
              placeholder="Select option"
              v-model="data.claims"
              @change="updateAssetsFilterClaims"
          >
            <el-option label="Hamısı" value="Hamısı">Hamısı</el-option>
            <el-option label="Var" value="Var">Var</el-option>
            <el-option label="Yoxdur" value="Yoxdur">Yoxdur</el-option>
          </el-select>
        </div>
        <!--end::Input-->
      </div>
      <!--end::Input group-->
      <!--begin::Input group-->
      <div class="mb-5">
        <!--begin::Label-->
        <label class="form-label fw-bold">Kalibrasiya:</label>
        <!--end::Label-->

        <!--begin::Input-->
        <div>
          <el-select
              class="form-select-solid"
              placeholder="Select option"
              v-model="data.calibration"
              @change="updateAssetsFilterCalibration"
          >
            <el-option label="Hamısı" value="Hamısı">Hamısı</el-option>
            <el-option label="Kalibrasiya olunub" value="Kalibrasiya olunub">Kalibrasiya olunub</el-option>
            <el-option label="Kalibrasiya olunmayıb" value="Kalibrasiya olunmayıb">Kalibrasiya olunmayıb</el-option>
            <el-option label="İstifadəyə yararsız" value="İstifadəyə yararsız">İstifadəyə yararsız</el-option>
          </el-select>
        </div>
        <!--end::Input-->
      </div>
      <!--end::Input group-->

      <!--begin::Input group-->
      <div class="mb-5">
        <!--begin::Label-->
        <label class="form-label fw-bold">Nömrə:</label>
        <!--end::Label-->


        <!--begin::Input-->
        <div>
          <Field
              class="form-control form-control-lg"
              type="text"
              name="keyword"
              placeholder="Yazmağa başlayın..."
              autocomplete="off"
              v-model="data.keyword"
              @input="updateAssetsFilterKeyword"
          />
        </div>
        <!--end::Input-->
      </div>
      <!--end::Input group-->

      <!--begin::Actions-->
      <div class="d-flex justify-content-end">
        <button
          type="reset"
          @click="resetFilters"
          class="btn btn-sm btn-white btn-active-light-primary me-2"
          data-kt-menu-dismiss="true"
        >
          Sıfırla
        </button>

        <button
          type="submit"
          @click="filterAssetsList"
          class="btn btn-sm btn-primary"
          data-kt-menu-dismiss="true"
        >
          Bağla
        </button>
      </div>
      <!--end::Actions-->
    </div>
    <!--end::Form-->
  </div>
  <!--end::Assets filter dropdown-->
</template>

<script lang="ts">
import { inject, defineComponent, ref, computed} from "vue";
import { Field } from "vee-validate";
import { useStore } from "vuex";
import store from "@/store";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {emitter} from "@/main";

interface Filter {
  status: string;
  calibration: string;
  type: string;
  manufacturer: string;
  keyword: string;
  claims: string;
}

export default defineComponent({
  name: "assets-filter-dropdown",
  components: {Field},
  methods: {
    resetFilters: function() {
      console.log("reset");
      this.data.status = "Hamısı";
      this.data.calibration = "Hamısı";
      this.data.type = "Hamısı";
      this.data.manufacturer = "Hamısı";
      this.data.keyword = "";
      this.data.claims = "Hamısı";
      this.filterAssetsList();
    },
    updateAssetsFilterStatus: function (selected) {
      this.data.status = selected;
      this.filterAssetsList();
    },
    updateAssetsFilterCalibration: function (selected) {
      this.data.calibration = selected;
      this.filterAssetsList();
    },
    updateAssetsFilterManufacturer: function (selected) {
      this.data.manufacturer = selected;
      this.filterAssetsList();
    },
    updateAssetsFilterType: function (selected) {
      this.data.type = selected;
      this.filterAssetsList();
    },
    updateAssetsFilterKeyword: function (selected) {
      this.data.keyword = selected.target.value;
      this.filterAssetsList();
    },
    updateAssetsFilterClaims: function (selected) {
      this.data.claims = selected;
      this.filterAssetsList();
    },
    filterAssetsList: function() {
      //console.log("update triggered", this.data);
      emitter.emit('filterAssetsList', this.data);
    }
  },
  setup() {
    const data = ref<Filter>({
      status: "Hamısı",
      calibration: "Hamısı",
      type: "Hamısı",
      manufacturer: "Hamısı",
      claims: "Hamısı",
      keyword: ""
    });

    return {
      data
    };
  }
});
</script>
