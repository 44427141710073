const DocMenuConfig: object = [
  {
    pages: [
      {
        heading: "Sayğac reestri",
        route: "/flowmeters",
        svgIcon: "media/icons/duotone/Interface/Grid.svg",
        fontIcon: "bi-text-left"
      }
    ]
  }
];

export default DocMenuConfig;
